import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "cartContainer",
    "cartTitle",
    "cartTable",
    "seatBookings",
    "pickedSeat",
    "seatIndex",
    "seatId",
    "eventSeatMapId",
    "seatPrice",
    "seatPriceTag",
    "totalPrice",
    "totalPriceField",
  ];

  connect() {
    this.cart = [];
    this.cartModified = false;
    this.modal = new bootstrap.Modal(document.getElementById("reservationModal"));
    this.initializeSeatMaps();
    this.initializeCarts();
  }

  initializeSeatMaps() {
    this.seatMaps = [];
    this.seatMapsById = {};
    this.eventSeatMapsById = {};

    this.getEventSeatMapsData().forEach((eventSeatMapData) => {
      const seatchart = this.createSeatChart(eventSeatMapData);
      this.addReservedSeatsTooltips(seatchart, eventSeatMapData);
      this.storeSeatMapData(seatchart, eventSeatMapData);
    });
  }

  getEventSeatMapsData() {
    const eventSeatMapsDataElement = this.element.querySelector("#eventSeatMapsData");
    return JSON.parse(eventSeatMapsDataElement.textContent);
  }

  createSeatChart(eventSeatMapData) {
    const element = document.getElementById(`mapContainer-${eventSeatMapData.id}`);
    const options = { ...eventSeatMapData.options, cart: { visible: false }, eventSeatMapId: eventSeatMapData.id };

    const seatchart = new window.Seatchart(element, options);
    seatchart.addEventListener("seatchange", (e) => this.handleSeatChange(e, eventSeatMapData));
    return seatchart;
  }

  addReservedSeatsTooltips(seatchart, eventSeatMapData) {
    eventSeatMapData.options.map.reservedSeats.forEach((reservedSeat) => {
      const seatElement = this.getSeatElement(seatchart, reservedSeat, eventSeatMapData.id);
      if (seatElement) {
        this.applyTooltip(seatElement, reservedSeat.playerName);
      }
    });
  }

  getSeatElement(seatchart, reservedSeat, mapId) {
    const seat = seatchart.store.getSeat({ row: reservedSeat.row, col: reservedSeat.col });
    const mapContainer = document.getElementById(`mapContainer-${mapId}`);
    return Array.from(mapContainer.querySelectorAll(".sc-seat")).find(
      (element) => element.textContent.trim() === seat.label
    );
  }

  applyTooltip(seatElement, playerName) {
    seatElement.setAttribute("data-bs-toggle", "tooltip");
    seatElement.setAttribute("data-bs-placement", "top");
    seatElement.setAttribute("aria-label", `Tady mačká: ${playerName}`);
    seatElement.setAttribute("data-bs-original-title", `Tady mačká: ${playerName}`);
    new bootstrap.Tooltip(seatElement);
  }

  storeSeatMapData(seatchart, eventSeatMapData) {
    this.seatMaps.push(seatchart);
    this.seatMapsById[eventSeatMapData.id] = seatchart;
    this.eventSeatMapsById[eventSeatMapData.id] = eventSeatMapData;
  }

  initializeCarts() {
    const cartTemplate = document.getElementById("cart-template");
    this.cartContainerTargets.forEach((cartContainer) => {
      const cartClone = cartTemplate.content.cloneNode(true);
      cartContainer.appendChild(cartClone);
    });
    this.updateCartDisplay();
  }

  handleSeatChange(e, eventSeatMapData) {
    const { previous, current } = e;
    if (current.state === "selected") {
      this.cart.push({ seat: current, eventSeatMapId: eventSeatMapData.id });
    } else if (previous.state === "selected") {
      this.cart = this.cart.filter((item) => !this.isSameSeat(item, current, eventSeatMapData.id));
    }
    this.updateCartDisplay();
  }

  isSameSeat(item, seat, eventSeatMapId) {
    return item.seat.index.row === seat.index.row && item.seat.index.col === seat.index.col && item.eventSeatMapId === eventSeatMapId;
  }

  updateCartDisplay() {
    this.cartContainerTargets.forEach((cartContainer) => {
      this.updateCartTitle(cartContainer);
      this.updateCartTable(cartContainer);
      this.updateTotalPrice();
    });
    this.cartModified = true;
  }

  updateCartTitle(cartContainer) {
    const cartTitleElement = cartContainer.querySelector('[data-seat-selection-target="cartTitle"]');
    cartTitleElement.textContent = `Košík (${this.cart.length})`;
  }

  updateCartTable(cartContainer) {
    const cartTableBody = cartContainer.querySelector('[data-seat-selection-target="cartTable"]');
    cartTableBody.innerHTML = "";

    this.cart.forEach((item, index) => {
      const cartItem = this.createCartItemRow(item, index);
      if (cartItem) {
        cartTableBody.appendChild(cartItem.row);
      }
    });
  }

  updateTotalPrice() {
    let totalPrice = this.cart.reduce((sum, item) => {
      const seatTypeOptions = this.getSeatTypeOptions(item.seat.type, item.eventSeatMapId);
      return sum + (seatTypeOptions ? seatTypeOptions.price : 0);
    }, 0);

    this.totalPriceTargets.forEach((totalPriceElement) => {
      if (totalPriceElement) {
        totalPriceElement.textContent = `Celkově: ${totalPrice.toFixed(2)},- CZK`;
      }
    });
  }

  createCartItemRow(item, index) {
    const seatTypeOptions = this.getSeatTypeOptions(item.seat.type, item.eventSeatMapId);
    if (!seatTypeOptions) return null;

    const seatPrice = seatTypeOptions.price;

    const row = document.createElement("tr");

    row.appendChild(this.createTicketCell(item, seatTypeOptions));
    row.appendChild(this.createPriceCell(seatPrice));
    row.appendChild(this.createDeleteButtonCell(index));

    return { row, seatPrice };
  }

  createTicketCell(item, seatTypeOptions) {
    const ticketTd = document.createElement("td");
    const ticketDiv = document.createElement("div");
    ticketDiv.className = `sc-ticket ${seatTypeOptions.cssClass}`;
    ticketDiv.setAttribute("data-seat-selection-target", "pickedSeat");

    const stripesDiv1 = document.createElement("div");
    stripesDiv1.className = "sc-ticket-stripes";
    const seatLabelDiv = document.createElement("div");
    seatLabelDiv.className = "sc-ticket-seat-label";
    seatLabelDiv.textContent = item.seat.label;
    const seatTypeDiv = document.createElement("div");
    seatTypeDiv.className = "sc-ticket-seat-type";
    seatTypeDiv.textContent = seatTypeOptions.label;
    const stripesDiv2 = stripesDiv1.cloneNode(true);

    ticketDiv.append(stripesDiv1, seatLabelDiv, seatTypeDiv, stripesDiv2);
    ticketTd.appendChild(ticketDiv);

    return ticketTd;
  }

  createPriceCell(seatPrice) {
    const priceTd = document.createElement("td");
    priceTd.setAttribute("data-seat-selection-target", "seatPrice");
    priceTd.textContent = `${seatPrice.toFixed(2)},- CZK`;
    return priceTd;
  }

  createDeleteButtonCell(index) {
    const deleteTd = document.createElement("td");
    const deleteBtn = document.createElement("button");
    deleteBtn.className = "sc-cart-btn sc-cart-btn-delete";
    deleteBtn.type = "button";
    deleteBtn.dataset.index = index;
    deleteBtn.addEventListener("click", (event) => this.removeSeat(event));

    const btnIconDiv = document.createElement("div");
    btnIconDiv.className = "sc-cart-btn-icon";
    deleteBtn.appendChild(btnIconDiv);
    deleteTd.appendChild(deleteBtn);

    return deleteTd;
  }

  removeSeat(event) {
    const index = event.currentTarget.dataset.index;
    const removedItem = this.cart.splice(index, 1)[0];

    const seatchart = this.seatMapsById[removedItem.eventSeatMapId];
    if (seatchart) {
      seatchart.setSeat(removedItem.seat.index, { state: "available" });
    }

    this.updateCartDisplay();
  }

  clearCart() {
    this.cart.forEach((item) => {
      const seatchart = this.seatMapsById[item.eventSeatMapId];
      if (seatchart) {
        seatchart.setSeat(item.seat.index, { state: "available" });
      }
    });

    this.cart = [];
    this.updateCartDisplay();
  }

  populateModal() {
    if (this.cart.length === 0) {
      this.showFlashMessage("Nejdříve si vyberte místa k rezervování.");
      return;
    }

    for (const seatMapId in this.seatMapsById) {
      const seatMapInfo = this.eventSeatMapsById[seatMapId];
      const minSeats = seatMapInfo.options.cart.seatsRequired;
      if (minSeats) {
        const seatCountForMap = this.cart.filter(
          (item) => item.eventSeatMapId === parseInt(seatMapId, 10)
        ).length;

        if (seatCountForMap > 0 && seatCountForMap != minSeats) {
          const seatMapName = seatMapInfo.name || `Map #${seatMapId}`;
          this.showFlashMessage(
            `Pro ${seatMapName} jsou povolené pouze týmové registrace. Musíte vybrat ${minSeats} míst/a.`
          );
          return;
        }
      }
    }

    if (this.cartModified) {
      this.seatBookingsTarget.innerHTML = "";

      this.cart.forEach((item, index) => {
        const uniqueIndex = `${item.eventSeatMapId}${item.seat.index.row}${item.seat.index.col}`;
        const newSeat = this.createSeatBookingFields(item, uniqueIndex);
        this.seatBookingsTarget.appendChild(newSeat);
      });

      const totalAmount = this.calculateTotalAmount();
      this.totalPriceFieldTarget.value = totalAmount;

      this.cartModified = false;
    }

    this.modal.show();
  }

  createSeatBookingFields(item, uniqueIndex) {
    const newSeat = document.getElementById("seat-booking-template").content.cloneNode(true);

    newSeat.querySelectorAll("input, label").forEach((el) => {
      if (el.name) el.name = el.name.replace("INDEX", uniqueIndex);
      if (el.id) el.id = el.id.replace("INDEX", uniqueIndex);
    });

    this.populateSeatBookingFields(newSeat, item);

    return newSeat;
  }

  populateSeatBookingFields(newSeat, item) {
    const seatIndexField = newSeat.querySelector('[data-seat-selection-target="seatIndex"]');
    const originalLabel = seatIndexField.dataset.originalLabel;
    const reservationLabel = document.querySelector(`a[href="#seat-map-${item.eventSeatMapId}"]`);
    seatIndexField.textContent = `${reservationLabel.textContent} - ${originalLabel} ${item.seat.label}`;

    const seatIdField = newSeat.querySelector('[data-seat-selection-target="seatId"]');
    seatIdField.value = JSON.stringify(item.seat.index);

    const eventSeatMapIdField = newSeat.querySelector('[data-seat-selection-target="eventSeatMapId"]');
    eventSeatMapIdField.value = item.eventSeatMapId;

    const seatPriceTag = newSeat.querySelector('[data-seat-selection-target="seatPriceTag"]');
    const seatTypeOptions = this.getSeatTypeOptions(item.seat.type, item.eventSeatMapId);
    seatPriceTag.textContent = `${seatTypeOptions.price.toFixed(2)},- CZK`;
  }

  calculateTotalAmount() {
    return this.cart.reduce((sum, item) => {
      const seatTypeOptions = this.getSeatTypeOptions(item.seat.type, item.eventSeatMapId);
      return seatTypeOptions ? sum + seatTypeOptions.price : sum;
    }, 0);
  }

  getSeatTypeOptions(type, eventSeatMapId) {
    const seatMap = this.seatMapsById[eventSeatMapId];
    if (seatMap) {
      return seatMap.options.map.seatTypes[type];
    } else {
      console.error(`Seat map with ID ${eventSeatMapId} not found.`);
      return null;
    }
  }

  showFlashMessage(message) {
    const flashContainer = document.getElementById("flash-messages");
    const flashMessage = document.createElement("div");
    flashMessage.className = "alert alert-warning fade show mb-md flash-message";
    flashMessage.setAttribute("role", "alert");

    flashMessage.innerHTML = `
      ${message}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    `;

    flashContainer.appendChild(flashMessage);
    setTimeout(() => {
      flashMessage.classList.remove("show");
      flashMessage.classList.add("fade");
      flashMessage.addEventListener("transitionend", () => flashMessage.remove());
    }, 3000);
  }
}
